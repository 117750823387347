import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Header, Groupper, Input, Button, Field, Message, Table } from 'react-frontier';
import { bindClick, bindFormChange, formatPlaceResult } from '../Util';
import { NotFound } from '../components';
import { Shipping, Location } from '../Classes';
import { Dropdown, Checkbox, Modal } from 'semantic-ui-react';
import { useTitle } from '../Hooks';
import Autocomplete from "react-google-autocomplete";
import moment from 'moment';
import API from '../API';
import Toast from 'react-hot-toast';
import Validator from '../Validator';


const initialFormState : Location = {
	street: '',
	exterior_number: '',
	interior_number: '',
	neighborhood: '',
	city: '',
	state: '',
	zipcode: '',
	country: '',
	lattitude: null,
	longitude: null,
	place_id: '',
	comments: ''
};

const types = [
	{ text: 'Shipping', value: 1 },
	{ text: 'Pickup', value: 20 }
]

var ShippingMethodEdit = ()=>{
	var { method_id } = useParams();
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>([]);
	var [method, setMethod] = useState<Shipping>(null);
	var [location, setLocation] = useState<Location>(null);
	var [editLocation, setEditLocation] = useState<boolean>(false);
	var [showLocationSearch, setShowLocationSearch] = useState<boolean>(true);
	var [disableModal, setDisableModal] = useState<boolean>(false);
	const IS_CREATE = method_id==='create';

	useEffect(() => {
		setTitle('Forma de envío');
		get();
	}, []);

	var get = ()=>{
		API.getShipmentMethod(parseInt(method_id)).then(res=>{
			if(res.error) return setLoadError(res.message);
			if(res.data.location){
				setShowLocationSearch(false);
				setLocation(res.data.location);
			}else{
				setLocation(initialFormState);
			}
			return setMethod(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error al obtener la forma de envío. (GSM-1)');
		})
	}

	var getPlaceData = async(place: any)=>{
		var formatedPlace = formatPlaceResult(place);
		setLocation({
			street: formatedPlace.route,
			exterior_number: formatedPlace.street_number,
			interior_number: null,
			neighborhood: formatedPlace.sublocality_level_1,
			city: formatedPlace.locality,
			state: formatedPlace.administrative_area_level_1,
			zipcode: formatedPlace.postal_code,
			country: formatedPlace.country,
			lattitude: formatedPlace.coordinates.lat,
			longitude: formatedPlace.coordinates.lng,
			place_id: null,
			comments: null
		});
		return setShowLocationSearch(false);
	}

	var enable = (setLoading: (l: boolean)=>void)=>{;
		setLoading(true);
		API.enableShipmentMethod(parseInt(method_id), !method.enabled).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Forma de envió actualizado!');
			var m = {...method};
			m.enabled = !m.enabled;
			if(!m.enabled){
				m.date_disabled = moment().unix();
			}
			setMethod(m);
			return setDisableModal(false);
		}).catch(err=>{
			return Toast.error('Hubo un error inesperado actualizando el producto. (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onEditChange = bindFormChange(method, setMethod);
	var onEditLocation = bindFormChange(location, setLocation);

	var update = (setLoading: (l: boolean)=>void)=>{
		var methodValidationRules: any = {
			name: [{
				rule: 'minLength', params: [3], label: 'Nombre'
			}],
			min_total: [{
				rule: 'number', label: 'Monto minimo'
			}]
		};
		var locationValidationRules: any = {};
		if (!method.needs_location) {
			locationValidationRules = {
				street: [{
					rule: 'empty',
					label: 'Calle'
				}],
				neighborhood: [{
					rule: 'empty',
					label: 'Colonia'
				}],
				exterior_number: [{
					rule: 'empty',
					label: 'Número exterior'
				}],
				zipcode: [{
					rule: 'empty',
					label: 'Codigo postal'
				}],
				state: [{
					rule: 'empty',
					label: 'Estado'
				}],
				city: [{
					rule: 'empty',
					label: 'Ciudad'
				}],
			};
		}
		var methodValidation = Validator(method, methodValidationRules);
		var locationValidation = Validator(location, locationValidationRules);

		setErrorPrompts([...methodValidation.prompts, ...locationValidation.prompts]);
		if(!methodValidation.valid || !locationValidation.valid) return;
		setLoading(true);
		API.editShipmentMethod(method.method_id, method.type_id, method.name, method.cost, method.needs_location, method.local, method.description, method.min_total, location).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Forma de envío actualizada!');
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado actualizando la forma de envío (S-UPD-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	if(!IS_CREATE && Number.isNaN(parseInt(method_id))){
		return <NotFound />
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if (!method || !location) {
		return <Header loading text='Cargando datos' />;
	}	

	return <div>
		<Groupper title={!IS_CREATE ? 'Editar forma de envío' : 'Crear forma de envío'} width={500} titleCentered actions={(
			<>
				<Button text={method.enabled ? "Deshabilitar" : 'Habilitar'} basic style={{ marginRight: 5 }} onClick={bindClick(setDisableModal, true)}/>
				{!IS_CREATE ? (
					<Button text="Guardar" color="black" onClick={update}/>
				) : (
					<Button text="Crear" color="black" />
				)}
			</>
		)}>
			<Input label='Nombre' value={method.name} onChange={onEditChange('name')}/>
			<Field label='Tipo'>
				<Dropdown
					placeholder='CFDI'
					fluid
					selection
					options={types.map(t => ({
						key: t.value,
						text: t.text,
						value: t.value
					}))}
					style={{marginBottom: 10}}
					value={method.type_id}
					onChange={onEditChange('type_id', true)}
				/>
			</Field>
			<Input textarea label='Comentarios' value={method.description} onChange={onEditChange('description')}/>
			{!method.local && (
				<Input label='Costo' value={method.cost} onChange={onEditChange('cost')}/>
			)}
			<Input label='Monto minimo' value={method.min_total} onChange={onEditChange('min_total')}/>
			<Field style={{ marginTop: 15 }}>
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Local' checked={method.local} onChange={() => onEditChange('local')(!method.local)}/>
				{method.local ? <Message style={{ marginBottom: 15 }}  text='El costo se calculará en función de los códigos postales locales.' type='warning'/> : null }
				<Checkbox toggle style={{display:'flex', marginBottom: 10}} label='Se requiere que el cliente proporcione su dirección de envío.' checked={method.needs_location} onChange={() => onEditChange('needs_location')(!method.needs_location)}/>
			</Field>
			{!method.needs_location ? <>
				{showLocationSearch && (
					<Field label='Ubicación' comment='Ubicación de Google Maps que le mostrará a el cliente'>
						<Autocomplete
							placeholder="Buscar dirección..."
							style={{ width: '100%', maxHeight: 200 }}
							apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
							options={{
								types: ['address'],
								componentRestrictions: { country: 'MX' },
								fields: ['geometry', 'address_components', 'formatted_address']
							}}
							onPlaceSelected={getPlaceData}
						/>
					</Field>
				)}
				{!showLocationSearch && (
					<Field style={{ paddingLeft: 15, paddingRight: 15 }}>
						<Table
							details
							striped
							title='Ubicación'
							style={{ marginTop: 15 }}
							button={!editLocation ? <>
								<Button text='Editar' iconName='pen' size='tiny' style={{ marginRight: 10}} onClick={bindClick(setEditLocation, true)}/>
								<Button text='Cambiar' iconName='pen' color='black' size='tiny' onClick={bindClick(setShowLocationSearch, true)}/>
							</> : (
								<Button text='Listo' iconName='check' color='green' size='tiny' onClick={bindClick(setEditLocation, false)}/>
							)}
						>
							<Table.Row data={['Calle', editLocation ? <Input value={location.street} onChange={onEditLocation('street')}/> : location.street]}/>
							<Table.Row data={['Número exterior', editLocation ? <Input value={location.exterior_number} onChange={onEditLocation('exterior_number')}/> : location.exterior_number]}/>
							<Table.Row data={['Número interior', editLocation ? <Input value={location.interior_number} onChange={onEditLocation('interior_number')}/> : location.interior_number]}/>
							<Table.Row data={['Colonia', editLocation ? <Input value={location.neighborhood} onChange={onEditLocation('neighborhood')}/> : location.neighborhood]}/>
							<Table.Row data={['Ciudad', editLocation ? <Input value={location.city} onChange={onEditLocation('city')}/> : location.city]}/>
							<Table.Row data={['Estado', editLocation ? <Input value={location.state} onChange={onEditLocation('state')}/> : location.state]}/>
							<Table.Row data={['Código postal', editLocation ? <Input value={location.zipcode} onChange={onEditLocation('zipcode')}/> : location.zipcode]}/>
						</Table>
					</Field>
				)}
			</> : (
				<Message type='info' text='Esta forma de envió requiere que el cliente proporcione su dirección de envío.'/>
			)}
			<Message type="error" list={errorPrompts} style={{ marginTop: 15}}/>
		</Groupper>
		<Modal open={disableModal} onClose={bindClick(setDisableModal, false)} size='mini'>
			<Modal.Header>{!method.enabled ? 'Habilitar' : 'Deshabilitar'} forma de envío</Modal.Header>
			<Modal.Content>
				<Header text={`¿${!method.enabled ? 'Habilitar' : 'Deshabilitar'} forma de envío?`}/>
				<Table
					details
					titleSize='small'
					title='Detalles'
					style={{ marginTop: 20 }}
					data={[
						['ID', method.method_id],
						['Nombre', method.name],
					]}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={bindClick(setDisableModal, false)} text='Cancelar' basic/>
				<Button onClick={enable} color={method.enabled ? 'red' : 'black'} text={!method.enabled ? 'Habilitar' : 'Deshabilitar'} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default ShippingMethodEdit;